import React from "react";
import styled from "styled-components";
import { dashboardLink } from "constants/Links";
import LinkButton from "components/LinkButton";

interface IActionProps {
  handleSearch: (value: string) => void;
  handleSignIn: () => void;
  handleGetStarted: () => void;
  isMobile?: boolean;
}

const GitHubIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C3.58 0 0 3.657 0 8.168c0 3.61 2.292 6.668 5.47 7.748.4.077.547-.176.547-.386 
    0-.19-.007-.693-.01-1.36-2.226.491-2.695-1.096-2.695-1.096-.364-.934-.89-1.182-.89-1.182-.727-.5.055-.49.055-.49.803.057 
    1.226.843 1.226.843.715 1.245 1.874.885 2.33.677.073-.533.28-.885.51-1.089-1.777-.208-3.644-.923-3.644-4.11 
    0-.906.31-1.646.82-2.227-.083-.209-.355-1.05.078-2.192 0 0 .67-.22 2.2.84a7.458 7.458 0 0 1 2-.282c.68.003 
    1.366.096 2 .28 1.53-1.06 2.2-.84 2.2-.84.434 1.143.162 1.983.08 2.192.51.58.82 
    1.32.82 2.227 0 3.195-1.87 3.9-3.654 4.104.287.257.54.76.54 1.53 0 1.105-.01 
    1.997-.01 2.268 0 .212.145.466.55.385C13.71 14.83 16 11.78 16 8.168 16 3.657 
    12.42 0 8 0z"
    />
  </svg>
);

const StarCount = styled.span`
  display: inline-block;
  font-weight: 500;
`;

const GitHubStarButton = () => (
  <StarButton onClick={() => window.open("https://github.com/beam-cloud/beta9/", "_blank")}>
    <IconWrapper>
      <GitHubIcon />
    </IconWrapper>
    <StarCount>Star</StarCount>
  </StarButton>
);

const ActionComponents: React.FC<IActionProps> = ({ isMobile }) => {
  return (
    <ActionWrapper>
      {!isMobile && <GitHubStarButton />}
      <LinkButton
        href={dashboardLink}
        width={isMobile ? "100%" : ""}
        buttonTheme="primary"
        height={!isMobile ? "32px" : ""}
      >
        Get Started
      </LinkButton>
    </ActionWrapper>
  );
};

export default ActionComponents;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const StarButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: transparent;
  color: #c9d1d9;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  font-family: sans-serif;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
`;
